.form {
    padding-bottom: 75px;
    @media screen and (max-width: 768px) {
        padding-bottom: 50px;
    }
    &__input {
        .MuiInputBase-root {
            font-family: $outfit;
            font-size: 0.9rem !important;
            border-radius: 0;
            &.Mui-disabled {
                opacity: 0.3;
            }
            .MuiNativeSelect-select,
            .MuiInputBase-input {
                background-color: var(--input-background);
            }
            .MuiSvgIcon-root {
                color: $grey;
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border: unset !important;
        }
        .MuiFormLabel-root {
            font-size: 1rem;
            color: $grey;
        }
    }

    &__step {
        color: $grey;
        font-size: 1rem;
        margin-bottom: 30px;
    }

    &__ionic__input {
    }

    &__ionic__input__error {
        border: solid;
        border-color: red;
        //background-color: red;
    }
}

Ion-content.form__content {
    .form__content--panda {
        background: var(--ion-color-primary);
        padding: 2rem;
        Ion-img {
            max-width: 200px;
            margin: auto;
        }
    }
    .form__content--form {
        background-color: var(--ion-color-secondary);
        padding: 2rem;
        .form {
            padding-bottom: 3rem;
        }
        .form__step {
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
            font-weight: 700;
        }
        .custom__stepper__override {
            margin-bottom: 0;
            .MuiStep-root {
                .MuiStepLabel-root {
                    .MuiStepLabel-iconContainer {
                        svg {
                            color: var(--ion-color-ternitary);
                            height: 4px;
                            circle {
                                r: 100;
                            }
                        }
                        &.Mui-active {
                            svg {
                                color: var(--input-background);
                                width: 5rem;
                                circle {
                                    r: 500;
                                }
                            }
                        }
                        &.Mui-completed {
                            svg {
                                background: black;
                            }
                        }
                    }
                }
            }
        }
    }
}
.no-padding-768 {
    @media screen and (min-width: 768px) {
        padding-bottom: 0px;
    }
}

Ion-content {
    .form {
        padding-bottom: 2.5rem;
    }
}

.tooltip__container {
    margin-top: 50px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        display: block; /* Remove any default whitespace */
    }

    .overlay-text {
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden; /* Hide text overflow */
        padding: 15px;

        .h3-style {
            font-size: 1.15em;
            font-weight: bold;
        }
    }
}
