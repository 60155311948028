.edit {
    max-width: 1770px;
    margin: auto;
    padding: 50px 30px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    @media screen and (max-width: 500px) {
        padding: 35px 25px;
    }
    &__container {
        position: relative;
        background-color: $yellow60;
        &::before {
            content: "";
            width: 50%;
            position: absolute;
            right: 0;
            height: 100%;
            background-color: $yellow;
            z-index: -1;
        }
    }
    &__left {
        padding: 0 30px 0 0;
        @media screen and (max-width: 768px) {
            max-width: 100%;
            padding: 0;
        }
    }
    &__right {
        padding: 0 0 0 30px;
        @media screen and (max-width: 768px) {
            padding-left: 0 !important;
            max-width: 100%;
        }
    }
    &__subtitle {
        font-size: 1.2rem;
        color: $black;
        font-weight: 700;
        margin: 0 0 25px;
        text-transform: uppercase;
    }
}

Ion-content {
    &.edit {
        height: calc(100% - 2rem);
        .edit__left {
            padding: 1rem;
            background: var(--ion-color-secondary);
        }
        .edit__right {
            padding: 1rem;
            background: var(--ion-color-primary);
            @media screen and (max-width: 768px) {
                padding-left: 1rem !important;
            }
        }
        .edit__subtitle {
            margin-bottom: 1rem;
        }
    }
}
