@use "../base/mixins" as m;

// Login & Register Stepper
.login {
    .login__content {
        display: flex;
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
        }
    }
    .login__left {
        width: 50%;
        background-color: $yellow;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1200px) {
            width: 100%;
        }
        img {
            padding: 100px;
            max-width: 100%;
        }
    }
    .login__right {
        width: 50%;
        background-color: $yellow60;
        padding: 100px 150px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1250px) {
            padding: 100px;
        }
        @media screen and (max-width: 1200px) {
            width: 100%;
            padding: 50px;
        }
        @media screen and (max-width: 500px) {
            padding: 35px 25px;
        }
    }
    .text__title {
        color: $grey;
        margin-bottom: 50px;
        text-transform: uppercase;
    }
    .text__subtitle {
        color: $black;
        font-size: 0.9rem;
        font-weight: 700;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    .text__desc {
        margin-bottom: 30px;
    }
    .text__input {
        margin-bottom: 20px;
    }
    &__signup {
        margin: 0 auto;
        padding: 3rem 1.5rem;
        text-align: center;
        max-width: 1000px;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        &--container {
            background-image: url("../../assets/images/bg-signup.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            width: 100%;
            position: relative;
            z-index: -1;
        }
        @media screen and (max-width: 500px) {
            padding: 35px 25px;
        }
    }
}

Ion-content.userLogin__content {
    .userLogin__content--panda {
        background-color: var(--ion-color-primary);
        Ion-img {
            max-width: 500px;
            margin: auto;
            padding: 2rem;
        }
    }
    .userLogin__content--form {
        background-color: var(--ion-color-secondary);
        padding: 2rem;
    }
}

.terms__and__conditions--modal {
    Ion-content {
        display: flex;
        .input__field {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
        }
        .error__message {
            text-align: center;
        }
        .terms__button--container {
            display: flex;
            justify-content: flex-end;
            padding-top: 1rem;
        }
    }
}
