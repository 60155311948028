.scrollable__container {
    overflow-y: auto;
    max-height: calc(100vh - 278px);
}

// Custom Scrollbar
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffb200;
}

.centered__parent {
    display: flex;
    justify-content: center;
}

.login__backdrop {
    z-index: 1201;
    background: #eeeeee;
    .login__spinner {
        position: absolute;
    }
}

.snackbar__container {
    margin: 2px;
}

.changepassword {
    &__password {
        margin-bottom: 10px;
    }

    &__confirm {
        margin-bottom: 20px;
    }
}

.forgotpassword {
    &__text {
        margin-bottom: 20px;
    }

    &__button {
        margin-bottom: 16px;
        margin-right: 15px;
    }

    &__icon {
        margin-right: 5px;
        margin-bottom: 2px;
    }
}

.register {
    &__strength {
        width: 85px;
        height: 8px;
        border-radius: 7px;
    }
}

.profile {
    &__textfield {
        margin-bottom: 10px;
    }

    &__button {
        margin-left: auto;
        margin-top: 20px;

        &__container {
            display: flex;
        }
    }
}

// Fonts
html {
    font-size: 20px;
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}
p {
    font-size: 1rem;
    margin: 0;
    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
}
h1 {
    font-size: 2rem;
    line-height: 1;
    margin: 0;
    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
}
span {
    margin: 0;
}

// Font classes
.text__title {
    margin-bottom: 10px !important;
    --margin-bottom: 10px !important;
    color: var(--color-grey);
    margin: 0;
    font-weight: 700;
}
.text__desc {
    margin-bottom: 15px;
    --margin-bottom: 15px;
}
.text__subtitle {
    font-weight: 700;
    margin-bottom: 5px;
    --margin-bottom: 5px;
}
.text__input {
    margin-bottom: 12px;
    --margin-bottom: 12px;
}

// Misc
.fullWidth {
    width: 100%;
}
.column-reverse {
    display: flex;
    flex-direction: column-reverse;
}
.no-padding {
    padding: 0 !important;
}

// Checkbox
Ion-checkbox {
    --checkbox-background: var(--input-background);
    width: 100%;
    p {
        width: 100%;
        white-space: initial;
        font-size: 0.7rem;
    }
}

// Input
input {
    border-radius: 0 !important;
}
Ion-input {
    background: var(--input-background);
    --padding-start: 0.5rem !important;
}

// Select
select {
    border-radius: 0 !important;
}
Ion-select {
    background: var(--input-background);
    --padding-start: 0.5rem !important;
    min-height: 44px;
    &::part(container) {
        margin-bottom: 5px;
    }
    &::part(label) {
        margin-bottom: 5px;
    }
    &.has-value {
        &::part(label) {
            transform: translateY(25%) scale(0.75);
        }
    }
    &.ionSelect__menu {
        margin-left: -8px;
        background: unset;
    }
}
Ion-alert {
    button {
        color: var(--ion-color-tertiary) !important;
        &[aria-checked="true"] {
            .alert-radio-label {
                color: var(--ion-color-tertiary) !important;
                text-decoration: underline;
            }
        }
    }
}

Ion-alert {
    [aria-checked="true"].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
        border-color: var(--input-background);
        .alert-radio-inner.sc-ion-alert-md {
            background-color: var(--input-background);
        }
    }
}

// header
Ion-header.app__header {
    box-shadow: unset !important;
    .app__toolbar {
        --border-width: 0 !important;
        .app__toolbar--logo {
            max-width: 150px;
            margin: auto;
        }
        .app__toolbar--titleCentered {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            padding-inline: 90px;
            padding-top: var(--padding-top);
            padding-bottom: var(--padding-bottom);
        }
    }
}

// Modal
Ion-modal {
    font-family: var(--ion-font-family);
}

// Speech bubble
.speech-bubble {
    position: relative;
    background: #11ab6e;
    // border-radius: 4px;
}

.speech-bubble:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 20%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #11ab6e;
    border-bottom: 0;
    margin-left: -20px;
    margin-bottom: -20px;
    z-index: 10;
}
