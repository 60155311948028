.input__phone {
    background-color: var(--input-background) !important;
    .form-control {
        width: 100% !important;
        border: unset !important;
        font-family: $outfit;
        font-size: 0.9rem !important;
        background-color: var(--input-background) !important;
        // @media screen and (max-width: 350px) {
        //     width: 100% !important;
        // }
    }
    .flag-dropdown {
        border: unset !important;
        .selected-flag {
            background-color: var(--input-background) !important;
        }
    }
}
