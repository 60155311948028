.header__footer__container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    position: relative;
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        padding: 25px 30px;
        height: 100px;
        gap: 25px;
        position: fixed;
        top: 0;
        left: 0;
        background: white;
        z-index: 15;
        a {
            display: flex;
        }
        @media screen and (max-width: 500px) {
            height: 75px;
        }
        #hbg-menu {
            width: 40px;
            cursor: pointer;
            @media screen and (max-width: 500px) {
                width: 33px;
            }
        }
        #hbg-close {
            z-index: 10;
            width: 40px;
            cursor: pointer;
            @media screen and (max-width: 500px) {
                width: 33px;
            }
        }
        .menu-close {
            display: none;
        }
        .menu-open {
            position: fixed;
            top: 100px;
            right: 0;
            background-color: $yellow;
            width: 100%;
            height: calc(100% - 100px);
            z-index: 5;
            min-width: 300px;
            @media only screen and (max-width: 500px) {
                top: 75px;
                height: calc(100% - 75px);
            }
            .menu-open__container {
                padding: 50px;
                // border: solid 1px $black;
                height: 100%;
                @media screen and (max-width: 1024px) {
                    padding: 35px;
                }
                .MuiTabs-root {
                    height: 100%;
                    .MuiTabs-scroller {
                        .MuiTabs-flexContainer {
                            flex-direction: column;
                            justify-content: space-around;
                            align-items: center;
                            height: 100%;
                            gap: 40px;
                            @media screen and (max-width: 1024px) {
                                // gap: 40px;
                                justify-content: unset;
                            }
                        }
                    }
                }
            }
            .header__cta--container {
                flex-direction: column;
            }
        }

        .logo {
            width: 208px;
            @media screen and (max-width: 500px) {
                width: 150px;
            }
        }
        .header__link {
            color: $grey;
            font-weight: 400;
            font-size: 0.9rem;
            padding: 0;
        }
        .header__cta {
            background: var(--ion-color-tertiary);
            color: var(--ion-color-tertiary-contrast);
            border-radius: var(--border-radius);
            font-weight: 400;
            text-transform: uppercase;
            padding: 0.75rem 1.25rem;
            border: solid 1px var(--ion-color-tertiary);
            font-size: 0.9rem;
            opacity: 1;
            height: 57px;
            &:hover {
                background-color: var(--input-background-shade);
                color: var(--ion-color-tertiary);
                border: solid 1px var(--input-background-shade);
            }
        }
        .header__link--container {
            display: flex;
            gap: 1rem;
            align-items: center;
            div {
                .MuiTabs-flexContainer {
                    gap: 2rem;
                }
            }
            @media screen and (max-width: 1024px) {
                flex-direction: column;
                width: fit-content;
                margin: auto;
            }
        }
        .header__cta--container {
            display: flex;
            gap: 1rem;
        }
        .header__language {
            border-radius: 0;
            background: #00000000;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 0.9rem;

            .MuiSelect-select {
                background: #00000000;
            }
            .MuiOutlinedInput-notchedOutline {
                border-width: 0;
            }
        }
        .MuiTabs-indicator {
            height: 0;
        }
    }

    .children {
        flex-grow: 1;
        margin-top: 100px;
        @media screen and (max-width: 500px) {
            margin-top: 75px;
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 30px;
        gap: 10px;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            text-align: center;
            gap: 40px;
            height: unset;
            padding: 35px 25px;
        }
        .logo {
            height: 75px;
        }
        .footer__subtitle {
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            color: $grey;
            padding-bottom: 16px;
        }
        .footer__link {
            color: $grey;
            font-size: 0.8rem;
            text-decoration-color: $grey;
            // margin-bottom: 8px;
            line-height: 2;
            margin: 0;
            width: fit-content;
        }
    }
}

.mobile__header__footer__container {
    .header {
    }

    .content {
        height: 100vh;
    }

    .footer {
    }
}

// Ionic
Ion-menu {
    Ion-content {
        --padding-top: 50px;
        .menu__link {
            color: unset;
            text-decoration: unset;
        }
    }
}
