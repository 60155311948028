.custom__stepper__override {
    //background-color: red;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 50px;

    > div > span > span > svg > text {
        display: none;
    }
}
