.button {
    &__primary {
        background: var(--ion-color-tertiary);
        color: var(--ion-color-tertiary-contrast);
        border-radius: var(--border-radius);
        font-weight: 400;
        text-transform: uppercase;
        padding: 0.75rem 1.25rem;
        border: solid 1px var(--ion-color-tertiary);
        &:hover {
            background-color: var(--input-background-shade);
            color: var(--ion-color-tertiary);
            border: solid 1px var(--input-background-shade);
        }
    }
    &__secondary {
        text-transform: uppercase;
        border-radius: 0;
        border: solid 1px $black;
        height: 50px;
        padding: 0.75rem 1.25rem;
        color: $black;
        font-weight: 400;
        &:hover {
            background-color: var(--ion-color-secondary);
            border: solid 1px var(--input-background-shade);
        }
    }
}
Ion-button {
    --background: var(--ion-color-tertiary);
    --color: var(--ion-color-tertiary-contrast);
    --border-radius: var(--border-radius);
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    &.ionButton__clear {
        --background: var(--ion-color-primary);
        // text-transform: capitalize;
    }
    &.ionButton__secondary {
        --background: #ffffff00;
        --color: var(--ion-color-tertiary);
        --border-style: solid;
        --border-color: var(--ion-color-tertiary);
        --border-width: 1px;
    }
}
