@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;700&display=swap");
$outfit: "Outfit", sans-serif;

// Color
$grey: #2d2d2d;
$black: #000;
$white: #fff;
$yellow: #f5fae8;
$yellow60: #f5fae860;

:root {
    // Border radius
    --border-radius: 0;
    --ion-font-family: "Outfit", sans-serif;
    --color-grey: #2e2e2e;
    --input-background: #cecdb8;
    --input-background-shade: #B7DB5C;
}
:host {
    font-family: var(--ion-font-family);
}
