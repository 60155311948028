.otp-group {
    display: flex;
    width: 100%;
    max-width: 360px;
    column-gap: 10px;
}

.otp-input {
    width: 100%;
    height: 60px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    font-family: $outfit;
    background-color: var(--input-background);

    &:focus-visible {
        outline: var(--input-background-shade) auto 1px;
    }
}
.error {
    border-color: red;
}
