.home {
    display: flex;
    flex-direction: row;
    height: 100%;

    .home__content {
        height: 100%;
        background-color: var(--ion-color-secondary);
        padding: 5rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        @media screen and (max-width: 500px) {
            padding: 35px 25px;
        }
        .text__title {
            text-transform: uppercase;
        }
        b,
        span {
            color: var(--ion-color-tertiary);
        }
    }
    .home__panda {
        height: 100%;
        display: flex;
        padding: 3rem;
        img {
            margin: auto;
        }
        &--container {
            background-color: var(--ion-color-primary);
            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }
    }
}
.home__codeDialog {
    overflow: auto;
    .MuiDialog-container {
        .MuiPaper-root {
            padding: 3rem;
            gap: 1rem;
            @media screen and (max-width: 600px) {
                padding: 2rem;
            }
            .MuiFormGroup-root {
                .MuiFormControlLabel-root {
                    width: fit-content;
                }
            }
        }
    }
    .home__code {
        font-weight: 700;
        font-size: 1.5rem;
        text-align: center;
        &--container {
            display: flex;
            align-items: center;
            gap: 1rem;
            @media screen and (max-width: 600px) {
                flex-direction: column;
            }
        }
    }
}

.home__codeModal {
    --height: auto;
    .home__codeModal--content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .home__code {
            font-weight: 700;
            font-size: 1.5rem;
            text-align: center;
            &--container {
                display: flex;
                align-items: center;
                gap: 1rem;
                flex-direction: column;
            }
        }
        Ion-checkbox {
            width: fit-content;
        }
    }
}

Ion-content {
    &.home {
        --background: var(--ion-color-secondary);
        .home__content {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: unset;
            // .home__code {
            //     font-weight: 700;
            //     font-size: 1.5rem;
            //     text-align: center;
            //     &--container {
            //         display: flex;
            //         flex-direction: column;
            //         align-items: center;
            //         gap: 1rem;
            //     }
            // }
        }
    }
}
